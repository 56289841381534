
export const transform = (input) => {
  return {
    ...input,
    fetchedAt: new Date().getTime()
  }
}

export const checkCache = (key, ttl) => {
  const nuxtApp = useNuxtApp();
  const data = nuxtApp.payload.data[key] || nuxtApp.static.data[key];
  if (data && data.fetchedAt && new Date().getTime() - data.fetchedAt < ttl) {
    return data;
  }
  return null;
}